.cuheaderText {
    letter-spacing: 2px;
    word-spacing: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: "FaseBulan", sans-serif;
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 50px;
  
  
    font-size: 60px; /* Set the font size */
    font-weight: bold; /* Set the font weight */
    line-height: 1.5; /* Optional: Adjust line height for better text spacing */
    padding: 10px 20px; /* Add padding to create space between text and border */
    display: inline-block; /* Make the bubble take up only as much width as needed */
    max-width: 100%; /* Allow the bubble to take the full available width when the text wraps */
    word-wrap: break-word; /* Allow the text to wrap within the bubble */
    color: var(--Color3);
  }

  .rounded-button {
    border-radius: 600px; /* Adjust the value to control the roundness of the border */
    padding: 0px; /* Add padding to make the button larger if needed */
  }
  .rounded-button img{
    height: 125px;
    width: 125px;
  }
  
  
  
  .cubodyText {
    letter-spacing: 2px;
    word-spacing: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: "FaseBulan", sans-serif;
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 50px; /* Optionally, add border-radius to make the corners rounded */
    /* -webkit-text-stroke: 2px black; Add an outline around the text characters */
  }
  
  .cuheaderTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  .cubodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 25px;
  }
  
  
  .cububbleWhite {
    background-color: var(--Color6); /* Replace with your desired background color */
    border-radius: 50px; /* Adjust this value to control the roundness of the bubble */
    line-height: 1.5; /* Optional: Adjust line height for better text spacing */
    padding: 10px 20px; /* Add padding to create space between text and border */
    display: inline-block; /* Make the bubble take up only as much width as needed */
    max-width: fit-content; /* Allow the bubble to shrink to fit the content */
    word-wrap: break-word; /* Allow the text to wrap within the bubble */
    
  }
  

  .infoText {
    color: var(--Color1); /* Set the text color for better contrast */
    font-size: 40px; /* Set the font size */
    font-weight: bold; /* Set the font weight */

    font-family: "FaseBulan", sans-serif;
    
  }
  
  
  
  @media screen and (max-width: 768px) {
  
    .cuheaderText {
  
      padding-top: 10px;
      padding-bottom: 10px;
    
    
      font-size: 30px; /* Set the font size */


      font-family: "FaseBulan", sans-serif;
    }


    .infoText {
        font-size: 20px; /* Set the font size */
        font-weight: bold; /* Set the font weight */


        font-family: "FaseBulan", sans-serif;
      }
      .rounded-button img{
        height: 80px;
        width: 80px;
      }
  }
  