

.Navbar {
  background-color: var(--Color3);
  font-family: "FaseBulan", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  position: sticky; /* Use sticky positioning */
  top: 0; /* Stick the Navbar to the top of the screen */
  width: 100%; /* Make it full-width */
  height: 80px; /* Set the initial height for the Navbar */
  z-index: 100; /* Ensure the Navbar is above other content */
  transition: height 0.3s ease; /* Add transition for height change */
  
}

.nav-container {
  display: flex;
  justify-content: space-between; /* Center the child elements horizontally */
  align-items:flex-end; /* Center the child elements vertically */
  padding: 0 20px; /* Add some padding to the Navbar */
  
}

.nav-menu {
  display: flex;
  list-style: none;
}

.nav-logopng {
  height: 80px; /* Adjust this value to fit your desired size */
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem; /* Add margin to create space between items, if needed */
  text-align: center;
  border-radius: 25px;
  border: solid transparent 3px;
  z-index: 100;
}

.nav-links {
  color: var(--Color1);
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.nav-item:hover {
  border-radius: 25px;
  border: solid var(--Color5) 3px;
  color: var(--Color5);
}

.nav-item .active {
  color: var(--Color5);
  width: 100%;
  justify-content: center;
  align-items: center;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 1650px) {
  .Navbar {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1400px) {
  .Navbar {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1276px) {
  .Navbar {
    font-size: 1rem;
  }
}




@media screen and (max-width: 1149px) {

  .Navbar {
    font-size: 2rem;
    
  }

  .nav-container {
    height: 80px;
    display: flex;
  justify-content: space-between; /* Center the child elements horizontally */
  align-items:center; /* Center the child elements vertically */
    
  }

  .nav-menu {
    /* Remove justify-content for small screens */
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    top: -100px;
    bottom: -125px;
    left: -200%;
    opacity: 1;
    z-index: 1;
  }

  .nav-menu.active {
    justify-content: center;
    background: var(--Color3);
    left: -0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .nav-item:hover {
    border-radius: 40px;
    border: solid var(--Color5) 3px;
    color: var(--Color5);
  }


  .nav-icon {
    display: block;
    font-size: 1.8rem;
    font-weight: bold;
    cursor: pointer;
    color: var(--Color5);
    z-index: 100;
    padding-right: 10px;
    margin-left: auto; /* Right-align the navigation icon */
  }

  .no-scroll {
    overflow: hidden;
  }

  .nav-logopng {
    height: 60px; /* Adjust this value to fit your desired size */
  }
}
