.gallery-display {
  display: flex;
  justify-content: center;
}

.gallery-item {
  padding: 10px;
  min-width: 30%;
  max-width: 75%;
}

.gallery-big{
  display: flex;
  width: 100%;
  height: 60vh;
  border: 2px solid var(--Color3);
  align-items: center;
  justify-content: center;
  background-color: white;
}

.gallery-big img {
  width: 900px;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  object-fit:contain; /* Maintain aspect ratio and fit within dimensions */
}

.gallery-small {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll; /* Enable horizontal scrolling for small screens */
  scroll-snap-type: x mandatory; /* Enable snapping behavior for the images */
  background-color: var(--Color3);
  border: 2px solid var(--Color3);
}
.gallery-small:hover {
  cursor: pointer;
}

.gallery-small img {
  flex: 0 0 auto;
  height: 150px;
  width: 150px;
  margin-right: 10px;
  border: 5px solid var(--Color1);
  scroll-snap-align: center; /* Center the images after scrolling */
}



@media screen and (max-width: 1500px) {
  .gallery-big img {
    height: 40vh;
    width: 700px;
  }
  .gallery-big {
    height: 40vh;
  }
  .gallery-small img {
    height: 125px;
    width: 125px;
  }
}
@media screen and (max-width: 1200px) {

  .gallery-item {
    max-width: 90%;
  }

  .gallery-big img {
    height: 50vh;
    width: 600px;
  }
  .gallery-big {
    height: 50vh;
  }
  .gallery-small img {
    height: 85px;
    width: 85px;
  }
}

@media screen and (max-width: 700px) {
  
  .gallery-big img {
    height: 60vh;
    width: 400px;
  }
  .gallery-big {
    height: 60vh;
  }
  .gallery-small img {
    height: 70px;
    width: 70px;
  }
}

@media screen and (max-width: 460px) {
  .gallery-big img {
    height: 60vh;
    width: 350px;
  }
  .gallery-big {
    height: 60vh;
  }
  .gallery-small img {
    height: 70px;
    width: 70px;
  }
}

@media screen and (max-width: 389px) {
  .gallery-big img {
    height: 60vh;
    width: 300px;
  }
  .gallery-big {
    height: 60vh;
  }
  .gallery-small img {
    height: 65px;
    width: 65px;
  }
}

@media screen and (max-width: 350px) {
  .gallery-big img {
    height: 40vh;
    width: 250px;
  }
  .gallery-big {
    height: 40vh;
  }
  .gallery-small img {
    height: 65px;
    width: 65px;
  }
}