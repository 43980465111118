.card-display {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow images to wrap into a column */
  }




  .card-item {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 25px;
    padding-bottom: 55px;
    width: 450px;
  }
  


.card-image img{
    width: 100%;
    height: 400px;
    border: 1px solid var(--Color3);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;
    object-fit: contain;
}
.card-image:hover {
  cursor: pointer;
}

.card-caption {
  max-width: 100%;
  min-height: 250px;
    font-family: "FaseBulan", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; /* Add this to center the text horizontally */
    height: auto; /* Set the height for the caption (adjust as needed) */
    background-color: var(--Color3);/* Add a semi-transparent background to make the text more readable */
    color: var(--Color1); /* Set the text color to white */
    padding: 10px; /* Add some padding to the caption for better aesthetics */

}
.card-caption:hover {
  cursor: pointer;
}

.card-title{
  font-weight: bold;
  font-size: 2.5rem;

}
.card-price{
  font-size: 2rem;

}
.card-clickme{
  font-weight: bold;
  font-size: 1.5rem;
  
}



  @media screen and (max-width: 899px) {
    .card-display img {
      flex-basis: 51%;
      height: auto;
    }
    
    .card-image {

    width: 100%;

    }

    .card-image img{

      width: 100%;
  
      }

      .card-caption {
        min-height: auto;
      }
  }