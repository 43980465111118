.imageContainer {
  height: 75vh; /* Set the desired height for the container */
  position: relative; /* Optional, but useful for positioning the image inside the container */
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.imageContainer img {
  height: 100%; /* Set the height of the image to 100% of the container's height */
  width: auto; /* This will automatically adjust the width to maintain the aspect ratio */
  display: block; /* Ensures the image behaves as a block element */
}

.headerText {
  letter-spacing: 2px;
  word-spacing: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-family: "FaseBulan", sans-serif;
  font-weight: bold;
  padding-top: 50px;
  padding-bottom: 50px;


  font-size: 60px; /* Set the font size */
  font-weight: bold; /* Set the font weight */
  line-height: 1.5; /* Optional: Adjust line height for better text spacing */
  padding: 10px 20px; /* Add padding to create space between text and border */
  display: inline-block; /* Make the bubble take up only as much width as needed */
  max-width: 100%; /* Allow the bubble to take the full available width when the text wraps */
  word-wrap: break-word; /* Allow the text to wrap within the bubble */
  color: var(--Color3);
  /* -webkit-text-stroke: 1px var(--Color1); Add an outline around the text characters */
}



.bodyText {
  
  letter-spacing: 2px;
  word-spacing: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-family: "FaseBulan", sans-serif;
  font-weight: bold;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 50px; /* Optionally, add border-radius to make the corners rounded */
  color: var(--Color3);
  /* -webkit-text-stroke: 1px var(--Color1); Add an outline around the text characters */
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 25px;
}

.bubbleWhite {
  background-color: var(--Color6); /* Replace with your desired background color */
  border-radius: 50px; /* Adjust this value to control the roundness of the bubble */
  font-size: 60px; /* Set the font size */
  font-weight: bold; /* Set the font weight */
  line-height: 1.5; /* Optional: Adjust line height for better text spacing */
  padding: 10px 20px; /* Add padding to create space between text and border */
  display: inline-block; /* Make the bubble take up only as much width as needed */
  max-width: fit-content; /* Allow the bubble to shrink to fit the content */
  word-wrap: break-word; /* Allow the text to wrap within the bubble */
}



.numberImages {
  width: 300px;
  height: 300px;
  padding: 1%;
}

@media screen and (max-width: 1260px) {
  .imageContainer {
    height: 50vh;
  }
}

@media screen and (max-width: 960px) {
  .mostPopular img {
    flex-basis: 51%;
    height: auto;
  }
}


@media screen and (max-width: 830px) {

  .imageContainer {
    height: 35vh;
  }


  .bubbleWhite {
    border-radius: 50px;
  }

  .bubbleWhite {
    font-size: 35px;
  }
  

  .headerText {

    padding-top: 20px;
    padding-bottom: 20px;
  
  
    font-size: 30px; /* Set the font size */

    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */

    
  }

  .headerTextContainer {
    padding-top: 30px;
    padding-bottom: 30px;

  }

  .bodyText {
    padding-top: 20px;
    padding-bottom: 20px;

    
  }

  .bodyContainer {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 460px) {
  .imageContainer {
    padding-top: 20px;
    height: 30vh;
  }
  .bubbleWhite {
    font-size: 30px;
  }
}
