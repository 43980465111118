

.carousel-control-prev {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(238deg) brightness(100%) contrast(101%);
  color: var(--Color1); /* Set the color to the value of --Color1 */
}

/* Right Arrow */
.carousel-control-next {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(238deg) brightness(100%) contrast(101%);
  color: var(--Color1); /* Set the color to the value of --Color1 */
}







.FullWidthCC {
  display: flex;
  justify-content: center;
}

.Carousel-Container {
  width: 700px; /* Set the width you desire */
  height: auto; /* Set the height you desire to accommodate image and caption */
  border: 1px solid var(--Color3); /* Add a border to create the box effect */
  overflow: hidden; /* Hide any content that overflows the container */
}

.carousel-indicators .active {
  background-color: var(--Color1); /* Set the background color for the active indicator */
}

.Carousel-Image img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;

  object-fit: contain;
}

.Carousel-Image:hover {
  cursor: pointer;
}

.Carousel-Caption {
  font-family: "FaseBulan", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30%; /* Set the height for the caption (adjust as needed) */
  background-color: var(--Color3);/* Add a semi-transparent background to make the text more readable */
  color: var(--Color1); /* Set the text color to white */
  padding: 10px; /* Add some padding to the caption for better aesthetics */
  text-align: center; /* Add this to center the text horizontally */
}

.Carousel-title{
  font-weight: bold;
  font-size: 3rem;

}
.Carousel-price{
  font-size: 2rem;

}
.Carousel-clickme{
  font-weight: bold;
  font-size: 1.5rem;
  
}


@media screen and (max-width: 960px) {


  .Carousel-Container {
    width: 500px; /* Set the width you desire */
  }
}



@media screen and (max-width: 700px) {


  .Carousel-Container {
    width: 300px; /* Set the width you desire */
  }
  .Carousel-title{
    font-size: 1.5rem;
  
  }
  .Carousel-price{
    font-size: 1.25rem;
  
  }
  .Carousel-clickme{
    font-weight: bold;
    font-size: 1rem;
    
  }
}

@media screen and (max-width: 300px) {


  .Carousel-Container {
    width: 200px; /* Set the width you desire */
  }
  .Carousel-title{
    font-size: 1.25rem;
  
  }
  .Carousel-price{
    font-size: 1rem;
  
  }
  .Carousel-clickme{
    font-weight: bold;
    font-size: .75rem;
    
  }
}