.center{
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
}

@font-face {
    font-family: "BLOBBYCHUG"; /* Name the font family whatever you prefer */
    src: url("./fonts/BLOBBYCHUG-Bold.ttf") format("truetype");
  
  
    font-family: "FaseBulan"; /* Name the font family whatever you prefer */
    src: url("./fonts/FaseBulan-BW633.otf") format("opentype");
  }

:root {

    font-family: 'FaseBulan', sans-serif; /* Use the font-family name you defined in @font-face */

    --Color1: #1e90ff;
    --Color2: #77d1fd;

    --Color3: #FFD700;
    --Color4: #cbffcc;

    --Color5: #FF0000;
    --Color6: #ffff;
    
  }

  .page {
    min-height: 65vh;
}

/* #FBAA01; */

  /* first green #32ff5e */